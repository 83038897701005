import MockAdapter from 'axios-mock-adapter';

import { api } from '../api-helpers/api-helpers';

export interface CustomMockRequest {
  url: string | RegExp;
  status: number;
  method?: 'get' | 'put' | 'post' | 'delete';
  replyForever?: boolean;
  body?: unknown;
}

export function getMockRequest({ url, status, method, body, replyForever }: CustomMockRequest) {
  const mockRequest: CustomMockRequest = {
    url,
    status,
    method: method ?? 'get',
    body,
    replyForever,
  };
  return mockRequest;
}

function mockRequests(requests?: CustomMockRequest[], delay = 0) {
  const mock = new MockAdapter(api.instance, {
    delayResponse: delay,
    onNoMatch: 'throwException',
  });

  requests?.forEach(request => {
    // logs in dev mode the mock data for easy reference
    console.info('Mock requests ', request);
    if (request.method === 'post') {
      if (request.replyForever) {
        mock.onPost(request.url).reply(request.status, request.body);
      }
      mock.onPost(request.url).replyOnce(request.status, request.body);
    } else if (request.method === 'delete') {
      if (request.replyForever) {
        mock.onDelete(request.url).reply(request.status, request.body);
      }
      mock.onDelete(request.url).replyOnce(request.status, request.body);
    } else if (request.method === 'put') {
      if (request.replyForever) {
        mock.onPut(request.url).reply(request.status, request.body);
      }
      mock.onPut(request.url).replyOnce(request.status, request.body);
    } else {
      if (request.replyForever) {
        mock.onGet(request.url).reply(request.status, request.body);
      }
      mock.onGet(request.url).replyOnce(request.status, request.body);
    }
  });
}

function shouldUseMocks(): boolean {
  // when testing with Cypress we want to control when to enable the use of mocks
  if (typeof Cypress !== 'undefined') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (Cypress.config('useMocks') === false) {
      return false;
    }
  }

  // enable setting the use of mock data via the useMocks response header
  const req = new XMLHttpRequest();
  req.open('GET', document.location.href, false);
  req.send(null);
  const useMocks = req.getResponseHeader('useMocks');
  if (!useMocks) {
    // use mocks
    return true;
  }
  return useMocks !== 'false';
}

export default {
  setupMocks(requests = [], delay: number) {
    if (shouldUseMocks()) {
      console.log('Using mock instead of api:', requests);
      mockRequests(requests, delay);
    } else {
      console.warn('========== Using API instead of mock ==========');
    }
  },
};
