// @ts-nocheck

export interface AccountGegevens {
  emailadres?: string;
  identificatie?: string;
}

export interface AccountInfo {
  mfaEnabled?: boolean;
  ssoEnabled?: boolean;
  idinVerified?: boolean;
  idinCheckAllowed?: boolean;
}

export interface ActieveKilometerbundel {
  kilometerbundel?: Kilometerbundel;
  dongel?: Dongel;
}

export interface ActuelePolisInformatie {
  actueleInformatie?: PolisInformatieItem[];
  oudereInformatie?: DocumentOverzichtItem[];
}

export interface Adres {
  adresregels?: string[];
  magAdresWijzigen?: boolean;
  verhuisdatum?: LocalDateTime;
}

export interface Adres1 {
  straatnaam?: string;
  /** @format int32 */
  huisnummer?: number;
  huisnummertoevoeging?: string;
  postcode?: string;
  plaatsnaam?: string;
  regel1?: string;
  regel2?: string;
  regel3?: string;
  regel4?: string;
  regel5?: string;
  regel6?: string;
}

export interface Adressen {
  huidigAdres?: Adres;
  toekomstigAdres?: Adres;
}

export interface AutoInzittendenOngevallenProductDetail {
  naam?: string;
  code?: string;
  groep?: ProductgroepType;
  clausules?: string[];
  voorwaardes?: string[];
  dekkingen?: string[];
  verzekerdeAuto?: VerzekerdeAuto;
}

export interface AutoProductDetail {
  naam?: string;
  code?: string;
  groep?: ProductgroepType;
  clausules?: string[];
  voorwaardes?: string[];
  pakket?: string;
  dekkingen?: string[];
  /** @format double */
  eigenRisico?: number;
  /** @format int32 */
  aantalSchadeVrijeJaren?: number;
  /** @format int32 */
  noClaimTrede?: number;
  /** @format double */
  noClaimKortingPercentage?: number;
  budget?: boolean;
  verzekerdeAuto?: VerzekerdeAuto;
}

export interface BankrekeningnummerMetOvereenkomstInformatie {
  polisnummer?: string;
  productnaam?: string;
  productsoort?: string;
  kenmerk?: string;
  bankrekeningnummer?: string;
  notaBetaler?: boolean;
}

export interface BankrekeningnummerWijziging {
  /** @pattern ^( *[nN][lL](-?| *)\d{2}(-?| *)[a-zA-Z]{4}(-?| *)((\d{10})|(\d{4}(-?| *)\d{4}(-?| *)\d{2})|(\d{4}(-?| *)\d{3}(-?| *)\d{3})) *)$ */
  nieuwBankrekeningnummer?: string;
  polisnummers?: string[];
}

export interface Basis {
  /** @format int64 */
  relatienummer?: number;
  polisnummer?: string;
  ingangsdatum?: LocalDate;
  einddatum?: LocalDate;
  situatieIngangsDatum?: LocalDate;
  origineleMaatschappij?: string;
  kenmerk?: string;
}

export interface Betaalafspraak {
  polisnummer?: string;
  iban?: string;
  betaalvorm?: Betaalvorm;
}

export interface Betaaltermijn {
  code?: BetaaltermijnType;
  naam?: string;
}

export enum BetaaltermijnType {
  BT_M = 'BT_M',
  BT_J = 'BT_J',
  BT_D = 'BT_D',
  BT_E = 'BT_E',
  BT_K = 'BT_K',
  BT_H = 'BT_H',
  BT_R = 'BT_R',
  BT_S = 'BT_S',
  BT_W = 'BT_W',
  BT_Z = 'BT_Z',
  BT4 = 'BT_4',
  BT_BUNDEL = 'BT_BUNDEL',
  DEFAULT = 'DEFAULT',
}

export interface Betaalvorm {
  naam?: string;
  code?: string;
}

export interface BetalingenStatus {
  schade?: boolean;
  zorg?: boolean;
  /** @format double */
  schadebetalingsachterstand?: number;
}

export interface BetalingsStatusRequestBody {
  /** @pattern \S */
  betaallinkId: string;
  /** @format int32 */
  status: number;
}

export interface Betalingsachterstand {
  /** @format double */
  bedrag?: number;
  betalingskenmerk?: string;
  betaallink?: string;
  /** @format int32 */
  status?: number;
  startDatum?: LocalDate;
  eindDatum?: LocalDate;
  polisgegevens?: Polisgegevens[];
  factuurnummer?: string;
  factuurdatum?: LocalDate;
  factuurVervaldatum?: LocalDate;
}

export interface BetalingsachterstandOverzicht {
  zorg?: boolean;
  /** @format int32 */
  aantalAchterstanden?: number;
}

export interface BetalingsachterstandenDetails {
  schade?: boolean;
  zorg?: boolean;
  betalingsachterstanden?: Betalingsachterstand[];
  /** @format double */
  totaalAchterstand?: number;
  /** @format double */
  totaalOpen?: number;
}

export interface BundelRit {
  /** @format int32 */
  bundelNummer?: number;
  startTijd?: LocalDateTime;
  eindTijd?: LocalDateTime;
  /** @format double */
  totaalGeredenKilometers?: number;
  /** @format double */
  bundelGeredenKilometers?: number;
  /** @format int32 */
  bundelNummerGekoppeld?: number;
}

export interface Case {
  caseActies?: CaseActie[];
  status?: CaseStatus;
  startdatum?: LocalDateTime;
  datumBeeindigd?: LocalDateTime;
  aanvullendeGegevens?: string;
  externeReferentie?: string;
  productnaam?: string;
}

export interface CaseActie {
  type?: string;
  status?: string;
  aanvullendeGegevens?: string;
  uuid?: string;
  datumBeeindigd?: LocalDateTime;
  externeReferentie?: string;
}

export enum CaseStatus {
  ACTIEF = 'ACTIEF',
  VOLTOOID = 'VOLTOOID',
  GEANNULEERD = 'GEANNULEERD',
}

export interface Claim {
  claimnummer?: string;
  factuurnummer?: string;
  ontvangstdatum?: LocalDate;
  schadedatum?: LocalDate;
  /** @format double */
  claimbedrag?: number;
  /** @format double */
  uitkering?: number;
  status?: string;
}

export interface ClaimMetClaimregels {
  claimnummer?: string;
  ontvangstdatum?: LocalDate;
  schadedatum?: LocalDate;
  toelichting?: string;
  /** @format double */
  claimbedrag?: number;
  /** @format double */
  uitkering?: number;
  status?: string;
  claimregels?: Claimregel[];
  uitkeringen?: Uitkering[];
}

export interface Claimregel {
  behandeling?: string;
  behandeldatum?: LocalDate;
  medicijn?: string;
  /** @format double */
  bedrag?: number;
  toelichting?: string;
  /** @format double */
  eigenbijdrage?: number;
  /** @format double */
  eigenrisico?: number;
  /** @format double */
  uitkering?: number;
}

export interface Collectiviteit {
  /** @format int32 */
  nummer?: number;
  naam?: string;
  /** @format double */
  kortingsPercentage?: number;
}

export interface CommunicatieAfspraken {
  emailVoorkeuren?: CommunicatieVoorkeuren;
  telefoonVoorkeuren?: CommunicatieVoorkeuren;
}

export interface CommunicatieAfsprakenMetHerkomst {
  communicatieType?: CommunicatieType;
  schadeCommunicatie?: boolean;
  zorgCommunicatie?: boolean;
  herkomst?: Herkomst;
}

export enum CommunicatieType {
  TELEFOON = 'TELEFOON',
  EMAIL = 'EMAIL',
}

export interface CommunicatieVoorkeuren {
  schadeCommunicatie?: boolean;
  zorgCommunicatie?: boolean;
}

export interface ConsentCookie {
  id?: UUID;
  permissions: Permissions;
  createdAt?: string;
  kanaal?: Kanaal;
}

export interface Contract {
  ingangsdatum?: LocalDate;
}

export interface Cookie {
  name?: string;
  value?: string;
  /** @format int32 */
  version?: number;
  path?: string;
  domain?: string;
}

export interface CrossSellBanner {
  header?: string;
  imageUrl?: string;
  product?: string;
  text?: string;
  title?: string;
  urlLink?: string;
  linkText?: string;
}

export interface DataPortabiliteitXML {
  persoonsgegevens?: Persoonsgegevens;
  overeenkomstDetail?: OvereenkomstDetail;
}

export interface DocumentItem {
  documentnaam?: string;
  url?: string;
  type?: string;
}

export interface DocumentOverzichtItem {
  datum?: string;
  documenten?: DocumentItem[];
}

export interface Dongel {
  status?: DongelStatus;
  imei?: string;
}

export interface DongelStatus {
  code?: DongelStatusType;
  naam?: string;
}

export enum DongelStatusType {
  AANGEVRAAGD = 'AANGEVRAAGD',
  GEACTIVEERD = 'GEACTIVEERD',
  GERETOURNEERD = 'GERETOURNEERD',
  KWIJT = 'KWIJT',
  ONTKOPPELD = 'ONTKOPPELD',
  RETOURAANGEVRAAGD = 'RETOURAANGEVRAAGD',
  RETOURBEVESTIGD = 'RETOURBEVESTIGD',
  VERSTUURD = 'VERSTUURD',
  ONBEKEND = 'ONBEKEND',
}

export interface DoorlopendeReisProductDetail {
  naam?: string;
  code?: string;
  groep?: ProductgroepType;
  clausules?: string[];
  voorwaardes?: string[];
  pakket?: string;
  dekkingen?: string[];
  gezinssituatie?: string;
  /** @format int32 */
  aantalDagenDekking?: number;
  dekkingsgebied?: string;
}

export interface EmailVerificatieCode {
  code?: string;
}

export interface Emailadres {
  adres?: string;
  geverifieerd?: boolean;
}

export interface EmailadresVerificatie {
  verificatieStatus?: EmailadresVerificatieStatus;
  emailadres?: Emailadres;
}

export enum EmailadresVerificatieStatus {
  GEEN_WIJZIGINGSVERZOEK = 'GEEN_WIJZIGINGSVERZOEK',
  VERLOPEN_WIJZIGINGVERZOEK = 'VERLOPEN_WIJZIGINGVERZOEK',
  GELDIG_WIJZIGINGVERZOEK = 'GELDIG_WIJZIGINGVERZOEK',
  VERIFICATIEMAIL_VERZONDEN = 'VERIFICATIEMAIL_VERZONDEN',
  EMAILADRES_NIET_BESCHIKBAAR = 'EMAILADRES_NIET_BESCHIKBAAR',
  AFGEROND = 'AFGEROND',
  NIET_AFGEROND = 'NIET_AFGEROND',
  AFGEROND_ZONDER_MAIL = 'AFGEROND_ZONDER_MAIL',
  UITGESTELD_AFGEROND = 'UITGESTELD_AFGEROND',
  UITGESTELD_AFGEROND_ZONDER_MAIL = 'UITGESTELD_AFGEROND_ZONDER_MAIL',
  ONGELDIGE_CODE = 'ONGELDIGE_CODE',
  AANVRAAG_VERLOPEN = 'AANVRAAG_VERLOPEN',
}

export interface EmailadresWijziging {
  adres?: string;
}

export interface GeverifieerdeGegevens {
  /** Man, Vrouw, of Onbekend */
  geslacht?: string;
  voorletters?: string;
  voorvoegsels?: string;
  achternaam?: string;
  voorvoegselsPartner?: string;
  partnernaam?: string;
  geboortedatum?: LocalDate;
}

export interface GeverifieerdeGegevensResponse {
  huidigeGegevens?: GeverifieerdeGegevens;
  nieuweGegevens?: GeverifieerdeGegevens;
  geverifieerdeGegevensStatus: GeverifieerdeGegevensStatus;
  naamVolgordeOpties?: Record<string, string>;
}

export enum GeverifieerdeGegevensStatus {
  SUCCESS = 'SUCCESS',
  NO_MATCH = 'NO_MATCH',
  MATCH = 'MATCH',
  ERROR = 'ERROR',
}

export interface GroeneKaartLink {
  urlDocumentRecenteGroeneKaart?: string;
}

export enum Herkomst {
  EMAILLINK = 'EMAILLINK',
  MIJNOHRA = 'MIJNOHRA',
  MEDEWERKER = 'MEDEWERKER',
  NOR = 'NOR',
  AANVRAAGPROCES = 'AANVRAAGPROCES',
}

export enum HerkomstEnum {
  EMAILLINK = 'EMAILLINK',
  MIJNOHRA = 'MIJNOHRA',
  MEDEWERKER = 'MEDEWERKER',
  NOR = 'NOR',
  AANVRAAGPROCES = 'AANVRAAGPROCES',
}

export interface HuidigeHuisdierenDeclaratieOverzicht {
  huidigeHuisdierenDeclaraties?: HuidigeHuisdierenDeclaraties;
  claimsInJaar?: Claim[];
  claimsOverig?: Claim[];
}

export interface HuidigeHuisdierenDeclaraties {
  startVerzekeringsjaar?: LocalDate;
  eindeVerzekeringsjaar?: LocalDate;
  /** @format double */
  uitgekeerdTotaal?: number;
  /** @format double */
  verzekerdTotaal?: number;
}

export interface HuisdierDekking {
  type?: string;
  /** @format double */
  premie?: number;
  /** @format double */
  verzekerdBedrag?: number;
}

export interface HuisdierProductDetail {
  naam?: string;
  code?: string;
  groep?: ProductgroepType;
  clausules?: string[];
  voorwaardes?: string[];
  pakket?: string;
  dekkingen?: HuisdierDekking[];
  hoofddekking?: HuisdierDekking;
  verzekerdHuisdier?: VerzekerdHuisdier;
}

/** @deprecated */
export interface IdinKoppeling {
  idinGekoppeld?: boolean;
  mfaEnabled?: boolean;
  ssoEnabled?: boolean;
  magKoppelen?: boolean;
  heeftZorg?: boolean;
  heeftSchade?: boolean;
}

export interface InboedelProductDetail {
  naam?: string;
  code?: string;
  groep?: ProductgroepType;
  clausules?: string[];
  voorwaardes?: string[];
  schadeDekking?: SchadeDekking;
  pakket?: string;
  dekkingen?: string[];
  product20?: boolean;
  /** @format double */
  eigenRisico?: number;
  gezinssituatie?: string;
  verzekerdeWoning?: VerzekerdeWoning;
}

export enum Kanaal {
  APP = 'APP',
  WEB = 'WEB',
}

export interface KeuzeschermItem {
  productnaam?: string;
  producticoon?: string;
  kenmerk?: string;
  statusnaam?: string;
  statusToelichting?: string;
  polisnummer?: string;
  url?: string;
}

export interface KilometerProductDetail {
  naam?: string;
  code?: string;
  groep?: ProductgroepType;
  clausules?: string[];
  voorwaardes?: string[];
  pakket?: string;
  dekkingen?: string[];
  /** @format double */
  eigenRisico?: number;
  /** @format int32 */
  aantalSchadeVrijeJaren?: number;
  /** @format int32 */
  noClaimTrede?: number;
  /** @format double */
  noClaimKortingPercentage?: number;
  budget?: boolean;
  verzekerdeAuto?: VerzekerdeAuto;
  actieveKilometerbundel?: ActieveKilometerbundel;
}

export interface Kilometerbundel {
  polisnummer?: string;
  /** @format int32 */
  nummer?: number;
  /** @format int32 */
  kilometerStand?: number;
  /** @format int32 */
  kilometerVerbruik?: number;
  /** @format int32 */
  kilometerbundelGrootte?: number;
  startdatum?: LocalDate;
  geldigTotDatum?: LocalDate;
  /** @format int32 */
  aantalDagen?: number;
}

export interface KortingscodeRequest {
  uitgever?: string;
}

/**
 * @format date
 * @example "2022-03-10"
 */
export type LocalDate = string;

/**
 * @format date-time
 * @example "2022-03-10T12:15:50"
 */
export type LocalDateTime = string;

export enum MeldingTYPE {
  BESTAANDACCOUNT_GEBRUIKERSNAAMGELIJK = 'BESTAANDACCOUNT_GEBRUIKERSNAAMGELIJK',
  BESTAANDACCOUNT_GEBRUIKERSNAAMAFWIJKEND = 'BESTAANDACCOUNT_GEBRUIKERSNAAMAFWIJKEND',
  GEENACCOUNT_GEBRUIKERSNAAMBEZET = 'GEENACCOUNT_GEBRUIKERSNAAMBEZET',
  GEENRELATIE = 'GEENRELATIE',
  ONGELDIGE_INVOER = 'ONGELDIGE_INVOER',
}

export interface MenuItem {
  text: string;
  url: string;
  openInNieuweTab?: boolean;
  subLinks?: SubmenuItem[];
}

export interface MfaStatus {
  enabled?: boolean;
}

export interface MobielnummerVerificatieRequest {
  mobielnummer?: string;
}

export interface MotorProductDetail {
  naam?: string;
  code?: string;
  groep?: ProductgroepType;
  clausules?: string[];
  voorwaardes?: string[];
  pakket?: string;
  dekkingen?: string[];
  /** @format double */
  eigenRisico?: number;
  /** @format int32 */
  aantalSchadeVrijeJaren?: number;
  /** @format int32 */
  noClaimTrede?: number;
  /** @format double */
  noClaimKortingPercentage?: number;
  verzekerdeMotor?: VerzekerdeMotor;
}

export enum NaamVolgorde {
  EIGEN = 'EIGEN',
  EIGEN_PARTNER = 'EIGEN_PARTNER',
  PARTNER_EIGEN = 'PARTNER_EIGEN',
  PARTNER = 'PARTNER',
}

export interface Naamwijziging {
  naamVolgorde?: NaamVolgorde;
}

export interface OngevallenProductDetail {
  naam?: string;
  code?: string;
  groep?: ProductgroepType;
  clausules?: string[];
  voorwaardes?: string[];
  gezinssituatie?: string;
}

export interface OpstalProductDetail {
  naam?: string;
  code?: string;
  groep?: ProductgroepType;
  clausules?: string[];
  voorwaardes?: string[];
  schadeDekking?: SchadeDekking;
  pakket?: string;
  dekkingen?: string[];
  inbraakpreventie?: string;
  gezinssituatie?: string;
  extraUitkering?: boolean;
  /** @format double */
  eigenRisico?: number;
  verzekerdeWoning?: VerzekerdeWoning;
}

export interface OvereenkomstBeeindigdOverzichtItem {
  productnaam?: string;
  producticoon?: string;
  kenmerk?: string;
  statusnaam?: string;
  statusToelichting?: string;
  polisnummer?: string;
}

export interface OvereenkomstDetail {
  basis?: Basis;
  status?: Status;
  /** Product */
  product?: Product;
  premie?: Premie;
  verzekeringnemer?: Relatie;
  collectiviteit?: Collectiviteit;
  betaalafspraak?: Betaalafspraak;
  contract?: Contract;
}

export interface OvereenkomstDetailTitelBlok {
  productnaam?: string;
  producticoon?: string;
  kenmerk?: string;
}

export interface OvereenkomstOverzichtItem {
  productnaam?: string;
  producticoon?: string;
  kenmerk?: string;
  premieToelichting?: string;
  statusnaam?: string;
  statusToelichting?: string;
  polisnummer?: string;
}

export interface Permissions {
  social?: boolean;
  personalization?: boolean;
  tracking?: boolean;
}

export interface Persoonsgegevens {
  /** @format int32 */
  relatienummer?: number;
  naam?: string;
  aanhef?: string;
  geboortedatum?: LocalDate;
  geverifieerd?: boolean;
}

export interface PolisAfspraken {
  communicatieAlleenDigitaal?: boolean;
  herkomst?: HerkomstEnum;
  heeftZorg?: boolean;
  heeftSchade?: boolean;
}

export interface PolisInformatieItem {
  naam?: string;
  url?: string;
  type?: string;
}

export interface Polisgegevens {
  /** @example "aansprakelijkheidsverzekering" */
  product?: string;
  polisnummer?: string;
  /** @format double */
  bedrag?: number;
}

export interface Premie {
  /** @format double */
  bedrag?: number;
  betaaltermijn?: Betaaltermijn;
}

/** Product */
export type Product = Product &
  ProductDetail &
  (
    | AutoProductDetail
    | KilometerProductDetail
    | AutoInzittendenOngevallenProductDetail
    | MotorProductDetail
    | HuisdierProductDetail
    | TijdelijkeReisProductDetail
    | DoorlopendeReisProductDetail
    | OngevallenProductDetail
    | InboedelProductDetail
    | OpstalProductDetail
    | RechtsbijstandProductDetail
    | ZorgProductDetail
  ) & {
    naam?: string;
    code?: string;
    groep?: ProductgroepType;
  };

export interface ProductDetail {
  naam?: string;
  code?: string;
  groep?: ProductgroepType;
  clausules?: string[];
  voorwaardes?: string[];
}

export enum ProductgroepType {
  SCHADE = 'SCHADE',
  HUISDIEREN = 'HUISDIEREN',
  ZORG = 'ZORG',
  ONBEKEND = 'ONBEKEND',
}

export interface RechtsbijstandProductDetail {
  naam?: string;
  code?: string;
  groep?: ProductgroepType;
  clausules?: string[];
  voorwaardes?: string[];
  dekkingen?: string[];
}

export interface Relatie {
  /** @format int64 */
  relatienummer?: number;
  voorletters?: string;
  voorvoegsels?: string;
  achternaam?: string;
  geboortedatum?: LocalDate;
  adres?: Adres1;
}

export interface Relatienummer {
  /** @format int32 */
  relatienummerValue?: number;
}

export enum ResultaatTYPE {
  VERWERKT = 'VERWERKT',
  NIET_VERWERKT = 'NIET_VERWERKT',
}

export interface SchadeDekking {
  code?: SchadeDekkingType;
  naam?: string;
}

export enum SchadeDekkingType {
  INB_BASIS = 'INB_BASIS',
  INB_EXUITG = 'INB_EXUITG',
  INB_ALLIN = 'INB_ALLIN',
  INB_COMPLEET = 'INB_COMPLEET',
  INB_UITGB = 'INB_UITGB',
  OPS_BASIS = 'OPS_BASIS',
  OPS_EXUITG = 'OPS_EXUITG',
  OPS_ALLIN = 'OPS_ALLIN',
  OPS_COMPLEET = 'OPS_COMPLEET',
  OPS_UITGB = 'OPS_UITGB',
  DEFAULT = 'DEFAULT',
}

export interface Sessie {
  isLoggedIn?: boolean;
  relatienummer?: string;
  naam?: string;
  loggedIn?: boolean;
}

export interface ShowDialogRequest {
  /** @pattern \S */
  cookieReset: string;
}

export interface ShowDialogResponse {
  showDialog?: boolean;
}

export interface SmscodeVerificatieCode {
  code?: string;
}

export interface Status {
  code?: StatusType;
  naam?: string;
}

export enum StatusType {
  ACTIEF = 'ACTIEF',
  AFGEWEZEN = 'AFGEWEZEN',
  AFGEWEZEN_OFFERTE = 'AFGEWEZEN_OFFERTE',
  BEEINDIGD = 'BEEINDIGD',
  CONVERSIE = 'CONVERSIE',
  INBEHANDELING = 'INBEHANDELING',
  LATER_INGAAND = 'LATER_INGAAND',
  OFFERTE = 'OFFERTE',
  OPGESCHORT = 'OPGESCHORT',
  PREMIEVRIJ842875 = 'PREMIEVRIJ_842_875',
  PREMIEVRIJAOVERZEKERDE1 = 'PREMIEVRIJ_AOVERZEKERDE_1',
  PREMIEVRIJAOVERZEKERDE2 = 'PREMIEVRIJ_AOVERZEKERDE_2',
  PREMIEVRIJ_OVERLIJDEN = 'PREMIEVRIJ_OVERLIJDEN',
  PREMIEVRIJ_VERZOEKKLANT = 'PREMIEVRIJ_VERZOEKKLANT',
  VERVALLEN = 'VERVALLEN',
  VOORLOPIG_GEACCEPTEERD = 'VOORLOPIG_GEACCEPTEERD',
  ONBEKEND = 'ONBEKEND',
}

export interface SubmenuItem {
  text: string;
  url: string;
  openInNieuweTab?: boolean;
}

export interface Telefoonnummer {
  nummer?: string;
  soort: TelefoonnummerSoort;
  geverifieerd?: boolean;
}

export enum TelefoonnummerSoort {
  MOBIEL = 'MOBIEL',
  VAST = 'VAST',
  ONBEKEND = 'ONBEKEND',
}

export interface TelefoonnummerVerificatie {
  verificatieStatus?: TelefoonnummerVerificatieStatus;
  telefoonnummer?: Telefoonnummer;
}

export enum TelefoonnummerVerificatieStatus {
  GEEN_WIJZIGINGSVERZOEK = 'GEEN_WIJZIGINGSVERZOEK',
  VERLOPEN_WIJZIGINGVERZOEK = 'VERLOPEN_WIJZIGINGVERZOEK',
  GELDIG_WIJZIGINGVERZOEK = 'GELDIG_WIJZIGINGVERZOEK',
  TIMEOUT_PERIODE_LOOPT = 'TIMEOUT_PERIODE_LOOPT',
  SMS_VERZONDEN = 'SMS_VERZONDEN',
  MOBIELNUMMER_ONGELDIG = 'MOBIELNUMMER_ONGELDIG',
  MAX_AANTAL_WIJZIGINGEN_OVERSCHREDEN = 'MAX_AANTAL_WIJZIGINGEN_OVERSCHREDEN',
  RELATIE_ONBEKEND = 'RELATIE_ONBEKEND',
  WIJZIGEN_NIET_MOGELIJK = 'WIJZIGEN_NIET_MOGELIJK',
  AFGEROND = 'AFGEROND',
  AFGEROND_ZONDER_MAIL = 'AFGEROND_ZONDER_MAIL',
  UITGESTELD_AFGEROND = 'UITGESTELD_AFGEROND',
  UITGESTELD_AFGEROND_ZONDER_MAIL = 'UITGESTELD_AFGEROND_ZONDER_MAIL',
  ONGELDIGE_CODE = 'ONGELDIGE_CODE',
  SMSCODE_VERLOPEN = 'SMSCODE_VERLOPEN',
}

export interface TelefoonnummerWijziging {
  nummer: string;
  soort: TelefoonnummerSoort;
}

export interface TijdelijkeReisProductDetail {
  naam?: string;
  code?: string;
  groep?: ProductgroepType;
  clausules?: string[];
  voorwaardes?: string[];
  pakket?: string;
  dekkingen?: string[];
  gezinssituatie?: string;
  terugkeerdatum?: LocalDate;
  vertrekdatum?: LocalDate;
  /** @format int32 */
  verzekeringsduur?: number;
  /** @format int32 */
  aantalVerzekerdePersonen?: number;
  dekkingsgebied?: string;
}

/**
 * @format uuid
 * @pattern [a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}
 */
export type UUID = string;

export interface Uitkering {
  /** @format double */
  bedrag?: number;
  uitkeringsdatum?: LocalDate;
  status?: string;
}

export interface VerwerkingsResultaat {
  resultaat?: ResultaatTYPE;
  melding?: MeldingTYPE;
}

export interface VerzekerdHuisdier {
  soort?: string;
  ras?: string;
  roepnaam?: string;
  geboortedatum?: LocalDate;
  geslacht?: string;
  chipnummer?: string;
}

export interface VerzekerdPersoon {
  /** @format int64 */
  relatienummer?: number;
  voorletters?: string;
  tussenvoegsels?: string;
  achternaam?: string;
  geboortedatum?: LocalDate;
  /** @format double */
  premie?: number;
  /** @format double */
  eigenRisico?: number;
  basisDekking?: string;
  aanvullendeDekkingen?: string[];
  tandartsDekking?: string;
  buitenlandDekking?: string;
}

export interface VerzekerdeAuto {
  merk?: string;
  model?: string;
  type?: string;
  kenteken?: string;
}

export interface VerzekerdeMotor {
  merk?: string;
  model?: string;
  type?: string;
  kenteken?: string;
}

export interface VerzekerdeWoning {
  straatnaam?: string;
  /** @format int32 */
  huisnummer?: number;
  huisnummertoevoeging?: string;
  postcode?: string;
  plaatsnaam?: string;
  /** @format int32 */
  bouwjaar?: number;
  /** @format double */
  herbouwwaarde?: number;
  gebruiksoppervlak?: string;
  woningtype?: string;
}

export interface ZorgProductDetail {
  naam?: string;
  code?: string;
  groep?: ProductgroepType;
  clausules?: string[];
  voorwaardes?: string[];
  verzekerdePersonen?: VerzekerdPersoon[];
}

export interface Zorgpas {
  naam?: string;
  geboortedatum?: LocalDate;
  relatienummer?: string;
}

export interface CrossSellBannerListParams {
  product?: string;
}

export interface CaseListParams {
  caseactieId?: string;
}

export interface VerhuiscaseActieAfwijzenCreateParams {
  caseactieId?: string;
}

export interface CommunicatieListParams1 {
  encryptedRelatienummer?: string;
}

export interface CommunicatieCreateParams1 {
  encryptedRelatienummer?: string;
}

import type {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  HeadersDefaults,
  ResponseType,
} from 'axios';
import axios from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<
  FullRequestParams,
  'body' | 'method' | 'query' | 'path'
>;

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || 'http://localhost:8080',
    });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig,
  ): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method &&
          this.instance.defaults.headers[
            method.toLowerCase() as keyof HeadersDefaults
          ]) ||
          {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    if (input instanceof FormData) {
      return input;
    }
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] =
        property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(
          key,
          isFileType ? formItem : this.stringifyFormItem(formItem),
        );
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (
      type === ContentType.FormData &&
      body &&
      body !== null &&
      typeof body === 'object'
    ) {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (
      type === ContentType.Text &&
      body &&
      body !== null &&
      typeof body !== 'string'
    ) {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData
          ? { 'Content-Type': type }
          : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title kpcbService API
 * @version 12.11
 * @baseUrl http://localhost:8080
 *
 * KPCB api gebruikt voor KPCF frontend
 */
export class Api<
  SecurityDataType extends unknown,
> extends HttpClient<SecurityDataType> {
  schadeFormulierResource = {
    /**
     * No description
     *
     * @tags Schade Formulier Resource
     * @name AanvraagschadeformulierCreate
     * @request POST:/kpcb/api/aanvraagschadeformulier
     */
    aanvraagschadeformulierCreate: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/kpcb/api/aanvraagschadeformulier`,
        method: 'POST',
        ...params,
      }),
  };
  accountResource = {
    /**
     * No description
     *
     * @tags Account Resource
     * @name AccountList
     * @request GET:/kpcb/api/account
     */
    accountList: (params: RequestParams = {}) =>
      this.request<AccountInfo, any>({
        path: `/kpcb/api/account`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Account Resource
     * @name IdinList
     * @request GET:/kpcb/api/account/idin
     * @deprecated
     */
    idinList: (params: RequestParams = {}) =>
      this.request<IdinKoppeling, any>({
        path: `/kpcb/api/account/idin`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Account Resource
     * @name MfaStatusList
     * @request GET:/kpcb/api/account/mfa-status
     */
    mfaStatusList: (params: RequestParams = {}) =>
      this.request<MfaStatus, any>({
        path: `/kpcb/api/account/mfa-status`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Account Resource
     * @name RelatienummerList
     * @request GET:/kpcb/api/account/relatienummer
     */
    relatienummerList: (params: RequestParams = {}) =>
      this.request<Relatienummer, any>({
        path: `/kpcb/api/account/relatienummer`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
  actuelePolisInformatieResource = {
    /**
     * No description
     *
     * @tags Actuele Polis Informatie Resource
     * @name ActuelepolisinformatieDetail
     * @request GET:/kpcb/api/actuelepolisinformatie/{polisnummer}
     */
    actuelepolisinformatieDetail: (
      polisnummer: string,
      params: RequestParams = {},
    ) =>
      this.request<ActuelePolisInformatie, any>({
        path: `/kpcb/api/actuelepolisinformatie/${polisnummer}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
  adresResource = {
    /**
     * No description
     *
     * @tags Adres Resource
     * @name AdressenList
     * @request GET:/kpcb/api/adressen
     */
    adressenList: (params: RequestParams = {}) =>
      this.request<Adressen, any>({
        path: `/kpcb/api/adressen`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
  afsprakenResource = {
    /**
     * No description
     *
     * @tags Afspraken Resource
     * @name CommunicatieList
     * @request GET:/kpcb/api/afspraken/communicatie
     */
    communicatieList: (params: RequestParams = {}) =>
      this.request<CommunicatieAfspraken, any>({
        path: `/kpcb/api/afspraken/communicatie`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Afspraken Resource
     * @name CommunicatieCreate
     * @request POST:/kpcb/api/afspraken/communicatie
     */
    communicatieCreate: (
      data: CommunicatieAfsprakenMetHerkomst,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/kpcb/api/afspraken/communicatie`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Afspraken Resource
     * @name PolissenList
     * @request GET:/kpcb/api/afspraken/polissen
     */
    polissenList: (params: RequestParams = {}) =>
      this.request<PolisAfspraken, any>({
        path: `/kpcb/api/afspraken/polissen`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Afspraken Resource
     * @name PolissenCreate
     * @request POST:/kpcb/api/afspraken/polissen
     */
    polissenCreate: (data: PolisAfspraken, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/kpcb/api/afspraken/polissen`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  bankrekeningnummerResource = {
    /**
     * No description
     *
     * @tags Bankrekeningnummer Resource
     * @name BankrekeningnummersList
     * @request GET:/kpcb/api/bankrekeningnummers
     */
    bankrekeningnummersList: (params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/kpcb/api/bankrekeningnummers`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bankrekeningnummer Resource
     * @name BankrekeningnummersCreate
     * @request POST:/kpcb/api/bankrekeningnummers
     */
    bankrekeningnummersCreate: (
      data: BankrekeningnummerWijziging,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/kpcb/api/bankrekeningnummers`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bankrekeningnummer Resource
     * @name OvereenkomstenList
     * @request GET:/kpcb/api/bankrekeningnummers/overeenkomsten
     */
    overeenkomstenList: (params: RequestParams = {}) =>
      this.request<BankrekeningnummerMetOvereenkomstInformatie[], any>({
        path: `/kpcb/api/bankrekeningnummers/overeenkomsten`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
  betalingenResource = {
    /**
     * No description
     *
     * @tags Betalingen Resource
     * @name AchterstandenList
     * @request GET:/kpcb/api/betalingen/achterstanden
     */
    achterstandenList: (params: RequestParams = {}) =>
      this.request<BetalingsachterstandenDetails, any>({
        path: `/kpcb/api/betalingen/achterstanden`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Betalingen Resource
     * @name AchterstandenOverzichtList
     * @request GET:/kpcb/api/betalingen/achterstanden/overzicht
     */
    achterstandenOverzichtList: (params: RequestParams = {}) =>
      this.request<BetalingsachterstandOverzicht, any>({
        path: `/kpcb/api/betalingen/achterstanden/overzicht`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Betalingen Resource
     * @name BetaallinkDetail
     * @request GET:/kpcb/api/betalingen/betaallink/{betalingskenmerk}
     */
    betaallinkDetail: (betalingskenmerk: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/kpcb/api/betalingen/betaallink/${betalingskenmerk}`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Betalingen Resource
     * @name StatusList
     * @request GET:/kpcb/api/betalingen/status
     * @deprecated
     */
    statusList: (params: RequestParams = {}) =>
      this.request<BetalingenStatus, any>({
        path: `/kpcb/api/betalingen/status`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Betalingen Resource
     * @name StatusCreate
     * @request POST:/kpcb/api/betalingen/status
     */
    statusCreate: (
      data: BetalingsStatusRequestBody,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/kpcb/api/betalingen/status`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  campagneResource = {
    /**
     * No description
     *
     * @tags Campagne Resource
     * @name CrossSellBannerList
     * @request GET:/kpcb/api/campagne-info/cross-sell-banner
     */
    crossSellBannerList: (
      query: CrossSellBannerListParams,
      params: RequestParams = {},
    ) =>
      this.request<CrossSellBanner, any>({
        path: `/kpcb/api/campagne-info/cross-sell-banner`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  };
  casesResource = {
    /**
     * No description
     *
     * @tags Cases Resource
     * @name CaseList
     * @request GET:/kpcb/api/cases/case
     */
    caseList: (query: CaseListParams, params: RequestParams = {}) =>
      this.request<Case, any>({
        path: `/kpcb/api/cases/case`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cases Resource
     * @name ClaimcasesList
     * @request GET:/kpcb/api/cases/claimcases
     */
    claimcasesList: (params: RequestParams = {}) =>
      this.request<Case[], any>({
        path: `/kpcb/api/cases/claimcases`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cases Resource
     * @name ClaimcasesArchiefList
     * @request GET:/kpcb/api/cases/claimcases/archief
     */
    claimcasesArchiefList: (params: RequestParams = {}) =>
      this.request<Case[], any>({
        path: `/kpcb/api/cases/claimcases/archief`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cases Resource
     * @name VerhuiscaseList
     * @request GET:/kpcb/api/cases/verhuiscase
     */
    verhuiscaseList: (params: RequestParams = {}) =>
      this.request<Case, any>({
        path: `/kpcb/api/cases/verhuiscase`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cases Resource
     * @name VerhuiscaseActieAfwijzenCreate
     * @request POST:/kpcb/api/cases/verhuiscase/actieAfwijzen
     */
    verhuiscaseActieAfwijzenCreate: (
      query: VerhuiscaseActieAfwijzenCreateParams,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/kpcb/api/cases/verhuiscase/actieAfwijzen`,
        method: 'POST',
        query: query,
        ...params,
      }),
  };
  dataPortabiliteitResource = {
    /**
     * No description
     *
     * @tags Data Portabiliteit Resource
     * @name DataportabiliteitDetail
     * @request GET:/kpcb/api/dataportabiliteit/{polisnummer}
     */
    dataportabiliteitDetail: (
      polisnummer: string,
      params: RequestParams = {},
    ) =>
      this.request<DataPortabiliteitXML, any>({
        path: `/kpcb/api/dataportabiliteit/${polisnummer}`,
        method: 'GET',
        ...params,
      }),
  };
  documentenResource = {
    /**
     * No description
     *
     * @tags Documenten Resource
     * @name BetalingsverledenList
     * @request GET:/kpcb/api/documenten/betalingsverleden
     */
    betalingsverledenList: (params: RequestParams = {}) =>
      this.request<DocumentOverzichtItem[], any>({
        path: `/kpcb/api/documenten/betalingsverleden`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Documenten Resource
     * @name RechtopinzageList
     * @request GET:/kpcb/api/documenten/rechtopinzage
     */
    rechtopinzageList: (params: RequestParams = {}) =>
      this.request<DocumentItem[], any>({
        path: `/kpcb/api/documenten/rechtopinzage`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Documenten Resource
     * @name DocumentenDetail
     * @request GET:/kpcb/api/documenten/{polisnummer}
     */
    documentenDetail: (polisnummer: string, params: RequestParams = {}) =>
      this.request<DocumentOverzichtItem[], any>({
        path: `/kpcb/api/documenten/${polisnummer}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Documenten Resource
     * @name GroenekaartDetail
     * @request GET:/kpcb/api/documenten/{polisnummer}/groenekaart
     */
    groenekaartDetail: (polisnummer: string, params: RequestParams = {}) =>
      this.request<GroeneKaartLink, any>({
        path: `/kpcb/api/documenten/${polisnummer}/groenekaart`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
  emailadresResource = {
    /**
     * No description
     *
     * @tags Emailadres Resource
     * @name EmailadresList
     * @request GET:/kpcb/api/emailadres
     */
    emailadresList: (params: RequestParams = {}) =>
      this.request<EmailadresVerificatie, any>({
        path: `/kpcb/api/emailadres`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Emailadres Resource
     * @name EmailadresCreate
     * @request POST:/kpcb/api/emailadres
     */
    emailadresCreate: (data: EmailadresWijziging, params: RequestParams = {}) =>
      this.request<EmailadresVerificatie, any>({
        path: `/kpcb/api/emailadres`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Emailadres Resource
     * @name BevestigenCreate
     * @request POST:/kpcb/api/emailadres/bevestigen
     */
    bevestigenCreate: (
      data: EmailVerificatieCode,
      params: RequestParams = {},
    ) =>
      this.request<EmailadresVerificatie, any>({
        path: `/kpcb/api/emailadres/bevestigen`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  huisdierenDeclaratieResource = {
    /**
     * No description
     *
     * @tags Huisdieren Declaratie Resource
     * @name DetailsDetail
     * @request GET:/kpcb/api/huisdieren-declaraties/{claimnummer}/details
     */
    detailsDetail: (claimnummer: string, params: RequestParams = {}) =>
      this.request<ClaimMetClaimregels, any>({
        path: `/kpcb/api/huisdieren-declaraties/${claimnummer}/details`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Huisdieren Declaratie Resource
     * @name HuisdierenDeclaratiesDetail
     * @request GET:/kpcb/api/huisdieren-declaraties/{polisnummer}
     */
    huisdierenDeclaratiesDetail: (
      polisnummer: string,
      params: RequestParams = {},
    ) =>
      this.request<HuidigeHuisdierenDeclaraties, any>({
        path: `/kpcb/api/huisdieren-declaraties/${polisnummer}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Huisdieren Declaratie Resource
     * @name OverzichtDetail
     * @request GET:/kpcb/api/huisdieren-declaraties/{polisnummer}/overzicht
     */
    overzichtDetail: (polisnummer: string, params: RequestParams = {}) =>
      this.request<HuidigeHuisdierenDeclaratieOverzicht, any>({
        path: `/kpcb/api/huisdieren-declaraties/${polisnummer}/overzicht`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
  kilometerbundelsResource = {
    /**
     * No description
     *
     * @tags Kilometerbundels Resource
     * @name Kilometerbundels
     * @summary Haalt kilometerbundels op voor polisnummer
     * @request GET:/kpcb/api/kilometerbundels/{polisnummer}
     */
    kilometerbundels: (polisnummer: string, params: RequestParams = {}) =>
      this.request<Kilometerbundel[], any>({
        path: `/kpcb/api/kilometerbundels/${polisnummer}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Kilometerbundels Resource
     * @name BundelRitten
     * @summary Haalt de ritten op voor een bundelnummer
     * @request GET:/kpcb/api/kilometerbundels/{polisnummer}/{bundelnummer}
     */
    bundelRitten: (
      bundelnummer: number,
      polisnummer: string,
      params: RequestParams = {},
    ) =>
      this.request<BundelRit[], any>({
        path: `/kpcb/api/kilometerbundels/${polisnummer}/${bundelnummer}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
  kortingscodeResource = {
    /**
     * No description
     *
     * @tags Kortingscode Resource
     * @name KortingscodeCreate
     * @request POST:/kpcb/api/kortingscode
     */
    kortingscodeCreate: (
      data: KortingscodeRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/kpcb/api/kortingscode`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  multiFactorResource = {
    /**
     * No description
     *
     * @tags Multi Factor Resource
     * @name MobielList
     * @request GET:/kpcb/api/mfa/mobiel
     */
    mobielList: (params: RequestParams = {}) =>
      this.request<TelefoonnummerVerificatie, any>({
        path: `/kpcb/api/mfa/mobiel`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Multi Factor Resource
     * @name MobielMfaList
     * @request GET:/kpcb/api/mfa/mobiel-mfa
     */
    mobielMfaList: (params: RequestParams = {}) =>
      this.request<TelefoonnummerVerificatie, any>({
        path: `/kpcb/api/mfa/mobiel-mfa`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Multi Factor Resource
     * @name MobielRequestCodeCreate
     * @request POST:/kpcb/api/mfa/mobiel/request-code
     */
    mobielRequestCodeCreate: (params: RequestParams = {}) =>
      this.request<TelefoonnummerVerificatie, any>({
        path: `/kpcb/api/mfa/mobiel/request-code`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Multi Factor Resource
     * @name MobielRequestCodeMobielnummerCreate
     * @request POST:/kpcb/api/mfa/mobiel/request-code-mobielnummer
     */
    mobielRequestCodeMobielnummerCreate: (
      data: MobielnummerVerificatieRequest,
      params: RequestParams = {},
    ) =>
      this.request<TelefoonnummerVerificatie, any>({
        path: `/kpcb/api/mfa/mobiel/request-code-mobielnummer`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Multi Factor Resource
     * @name MobielValidateCodeCreate
     * @request POST:/kpcb/api/mfa/mobiel/validate-code
     */
    mobielValidateCodeCreate: (
      data: SmscodeVerificatieCode,
      params: RequestParams = {},
    ) =>
      this.request<TelefoonnummerVerificatie, any>({
        path: `/kpcb/api/mfa/mobiel/validate-code`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  overeenkomstenResource = {
    /**
     * No description
     *
     * @tags Overeenkomsten Resource
     * @name OvereenkomstenList
     * @request GET:/kpcb/api/overeenkomsten
     */
    overeenkomstenList: (params: RequestParams = {}) =>
      this.request<OvereenkomstOverzichtItem[], any>({
        path: `/kpcb/api/overeenkomsten`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Overeenkomsten Resource
     * @name BeeindigdList
     * @request GET:/kpcb/api/overeenkomsten/beeindigd
     */
    beeindigdList: (params: RequestParams = {}) =>
      this.request<OvereenkomstBeeindigdOverzichtItem[], any>({
        path: `/kpcb/api/overeenkomsten/beeindigd`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Overeenkomsten Resource
     * @name KeuzeschermDeclarerenDetail
     * @request GET:/kpcb/api/overeenkomsten/keuzescherm/declareren/{product}
     */
    keuzeschermDeclarerenDetail: (
      product: string,
      params: RequestParams = {},
    ) =>
      this.request<KeuzeschermItem[], any>({
        path: `/kpcb/api/overeenkomsten/keuzescherm/declareren/${product}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Overeenkomsten Resource
     * @name KeuzeschermHervattenDetail
     * @request GET:/kpcb/api/overeenkomsten/keuzescherm/hervatten/{product}
     */
    keuzeschermHervattenDetail: (product: string, params: RequestParams = {}) =>
      this.request<KeuzeschermItem[], any>({
        path: `/kpcb/api/overeenkomsten/keuzescherm/hervatten/${product}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Overeenkomsten Resource
     * @name KeuzeschermOpzeggenDetail
     * @request GET:/kpcb/api/overeenkomsten/keuzescherm/opzeggen/{product}
     */
    keuzeschermOpzeggenDetail: (product: string, params: RequestParams = {}) =>
      this.request<KeuzeschermItem[], any>({
        path: `/kpcb/api/overeenkomsten/keuzescherm/opzeggen/${product}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Overeenkomsten Resource
     * @name KeuzeschermSchademeldenDetail
     * @request GET:/kpcb/api/overeenkomsten/keuzescherm/schademelden/{product}
     */
    keuzeschermSchademeldenDetail: (
      product: string,
      params: RequestParams = {},
    ) =>
      this.request<KeuzeschermItem[], any>({
        path: `/kpcb/api/overeenkomsten/keuzescherm/schademelden/${product}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Overeenkomsten Resource
     * @name KeuzeschermSchorsenDetail
     * @request GET:/kpcb/api/overeenkomsten/keuzescherm/schorsen/{product}
     */
    keuzeschermSchorsenDetail: (product: string, params: RequestParams = {}) =>
      this.request<KeuzeschermItem[], any>({
        path: `/kpcb/api/overeenkomsten/keuzescherm/schorsen/${product}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Overeenkomsten Resource
     * @name KeuzeschermWijzigenDetail
     * @request GET:/kpcb/api/overeenkomsten/keuzescherm/wijzigen/{product}
     */
    keuzeschermWijzigenDetail: (product: string, params: RequestParams = {}) =>
      this.request<KeuzeschermItem[], any>({
        path: `/kpcb/api/overeenkomsten/keuzescherm/wijzigen/${product}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Overeenkomsten Resource
     * @name PaginamenuList
     * @request GET:/kpcb/api/overeenkomsten/paginamenu
     */
    paginamenuList: (params: RequestParams = {}) =>
      this.request<MenuItem[], any>({
        path: `/kpcb/api/overeenkomsten/paginamenu`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Overeenkomsten Resource
     * @name AanvraaggroenekaartCreate
     * @request POST:/kpcb/api/overeenkomsten/{polisnummer}/aanvraaggroenekaart
     */
    aanvraaggroenekaartCreate: (
      polisnummer: string,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/kpcb/api/overeenkomsten/${polisnummer}/aanvraaggroenekaart`,
        method: 'POST',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Overeenkomsten Resource
     * @name DetailsDetail
     * @request GET:/kpcb/api/overeenkomsten/{polisnummer}/details
     */
    detailsDetail: (polisnummer: string, params: RequestParams = {}) =>
      this.request<OvereenkomstDetail, any>({
        path: `/kpcb/api/overeenkomsten/${polisnummer}/details`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Overeenkomsten Resource
     * @name DetailsTitelDetail
     * @request GET:/kpcb/api/overeenkomsten/{polisnummer}/details/titel
     */
    detailsTitelDetail: (polisnummer: string, params: RequestParams = {}) =>
      this.request<OvereenkomstDetailTitelBlok, any>({
        path: `/kpcb/api/overeenkomsten/${polisnummer}/details/titel`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Overeenkomsten Resource
     * @name PaginamenuDetail
     * @request GET:/kpcb/api/overeenkomsten/{polisnummer}/paginamenu
     */
    paginamenuDetail: (polisnummer: string, params: RequestParams = {}) =>
      this.request<MenuItem[], any>({
        path: `/kpcb/api/overeenkomsten/${polisnummer}/paginamenu`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
  overzichtResource = {
    /**
     * No description
     *
     * @tags Overzicht Resource
     * @name PaginamenuList
     * @request GET:/kpcb/api/overzicht/paginamenu
     */
    paginamenuList: (params: RequestParams = {}) =>
      this.request<MenuItem[], any>({
        path: `/kpcb/api/overzicht/paginamenu`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
  persoonsgegevensResource = {
    /**
     * No description
     *
     * @tags Persoonsgegevens Resource
     * @name PersoonsgegevensList
     * @request GET:/kpcb/api/persoonsgegevens
     */
    persoonsgegevensList: (params: RequestParams = {}) =>
      this.request<Persoonsgegevens, any>({
        path: `/kpcb/api/persoonsgegevens`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Persoonsgegevens Resource
     * @name PersoonsgegevensPut
     * @request PUT:/kpcb/api/persoonsgegevens
     */
    persoonsgegevensPut: (data: Naamwijziging, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/kpcb/api/persoonsgegevens`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Persoonsgegevens Resource
     * @name VerificatiePut
     * @request PUT:/kpcb/api/persoonsgegevens/verificatie/{processingId}
     */
    verificatiePut: (processingId: UUID, params: RequestParams = {}) =>
      this.request<GeverifieerdeGegevensResponse, any>({
        path: `/kpcb/api/persoonsgegevens/verificatie/${processingId}`,
        method: 'PUT',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Persoonsgegevens Resource
     * @name VerificatieCreate
     * @request POST:/kpcb/api/persoonsgegevens/verificatie/{processingId}
     */
    verificatieCreate: (processingId: UUID, params: RequestParams = {}) =>
      this.request<GeverifieerdeGegevensResponse, any>({
        path: `/kpcb/api/persoonsgegevens/verificatie/${processingId}`,
        method: 'POST',
        format: 'json',
        ...params,
      }),
  };
  telefoonnummersResource = {
    /**
     * No description
     *
     * @tags Telefoonnummers Resource
     * @name MobielList
     * @request GET:/kpcb/api/telefoonnummers/mobiel
     */
    mobielList: (params: RequestParams = {}) =>
      this.request<TelefoonnummerVerificatie, any>({
        path: `/kpcb/api/telefoonnummers/mobiel`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Telefoonnummers Resource
     * @name MobielCreate
     * @request POST:/kpcb/api/telefoonnummers/mobiel
     */
    mobielCreate: (data: TelefoonnummerWijziging, params: RequestParams = {}) =>
      this.request<TelefoonnummerVerificatie, any>({
        path: `/kpcb/api/telefoonnummers/mobiel`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Telefoonnummers Resource
     * @name MobielValideerSmsCodeCreate
     * @request POST:/kpcb/api/telefoonnummers/mobiel/valideer-sms-code
     */
    mobielValideerSmsCodeCreate: (
      data: SmscodeVerificatieCode,
      params: RequestParams = {},
    ) =>
      this.request<TelefoonnummerVerificatie, any>({
        path: `/kpcb/api/telefoonnummers/mobiel/valideer-sms-code`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Telefoonnummers Resource
     * @name VastList
     * @request GET:/kpcb/api/telefoonnummers/vast
     */
    vastList: (params: RequestParams = {}) =>
      this.request<Telefoonnummer, any>({
        path: `/kpcb/api/telefoonnummers/vast`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Telefoonnummers Resource
     * @name VastCreate
     * @request POST:/kpcb/api/telefoonnummers/vast
     */
    vastCreate: (data: TelefoonnummerWijziging, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/kpcb/api/telefoonnummers/vast`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Telefoonnummers Resource
     * @name VastDelete
     * @request DELETE:/kpcb/api/telefoonnummers/vast
     */
    vastDelete: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/kpcb/api/telefoonnummers/vast`,
        method: 'DELETE',
        ...params,
      }),
  };
  zorgpasResource = {
    /**
     * No description
     *
     * @tags Zorgpas Resource
     * @name ZorgpasList
     * @request GET:/kpcb/api/zorgpas
     */
    zorgpasList: (params: RequestParams = {}) =>
      this.request<Zorgpas[], any>({
        path: `/kpcb/api/zorgpas`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
  accountResourceUnsecure = {
    /**
     * No description
     *
     * @tags Account Resource Unsecure
     * @name AccountCreate
     * @request POST:/kpcb/system/account
     */
    accountCreate: (data: AccountGegevens, params: RequestParams = {}) =>
      this.request<VerwerkingsResultaat, any>({
        path: `/kpcb/system/account`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  afsprakenResourceUnsecure = {
    /**
     * No description
     *
     * @tags Afspraken Resource Unsecure
     * @name CommunicatieList
     * @request GET:/kpcb/system/afspraken/communicatie
     */
    communicatieList: (
      query: CommunicatieListParams1,
      params: RequestParams = {},
    ) =>
      this.request<CommunicatieAfspraken, any>({
        path: `/kpcb/system/afspraken/communicatie`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Afspraken Resource Unsecure
     * @name CommunicatieCreate
     * @request POST:/kpcb/system/afspraken/communicatie
     */
    communicatieCreate: (
      query: CommunicatieCreateParams1,
      data: CommunicatieAfsprakenMetHerkomst,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/kpcb/system/afspraken/communicatie`,
        method: 'POST',
        query: query,
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  cookieResourceUnsecure = {
    /**
     * No description
     *
     * @tags Cookie Resource Unsecure
     * @name CookieCreate
     * @request POST:/kpcb/system/cookie
     */
    cookieCreate: (data: ConsentCookie, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/kpcb/system/cookie`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cookie Resource Unsecure
     * @name CookieCreate2
     * @request POST:/kpcb/system/cookie/{kanaal}
     * @originalName cookieCreate
     * @duplicate
     */
    cookieCreate2: (
      kanaal: Kanaal,
      data: ShowDialogRequest,
      params: RequestParams = {},
    ) =>
      this.request<ShowDialogResponse, any>({
        path: `/kpcb/system/cookie/${kanaal}`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  sessieResourceUnsecure = {
    /**
     * No description
     *
     * @tags Sessie Resource Unsecure
     * @name IsLoggedInList
     * @request GET:/kpcb/system/is-logged-in
     */
    isLoggedInList: (params: RequestParams = {}) =>
      this.request<Sessie, any>({
        path: `/kpcb/system/is-logged-in`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
}
