import MockProvider from './development/MockProvider';
import { registerWebComponents } from './WebComponentDefiner';

declare global {
  interface Window {
    mockApiCalls: () => void;
  }
}

window.mockApiCalls = () => {
  MockProvider.setupMocks([], 0);
};

registerWebComponents();
