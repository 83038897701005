const webComponents: Record<string, Promise<any>> = {
  'achterstallige-betalingen': import(
    './components/AchterstalligeBetalingen/AchterstalligeBetalingen.ce.vue'
  ),
  'actuele-polis-informatie': import(
    './components/ActuelePolisInformatie/ActuelePolisInformatie.ce.vue'
  ),
  'adres-gegevens': import('./components/AdresGegevens/AdresGegevens.ce.vue'),
  betalingsstatus: import('./components/Betalingsstatus/Betalingsstatus.ce.vue'),
  'betalingstatus-ideal': import('./components/BetalingstatusIdeal/BetalingstatusIdeal.ce.vue'),
  claimcases: import('./components/Claimcases/Claimcases.ce.vue'),
  communicatievoorkeuren: import(
    './components/Communicatievoorkeuren/Communicatievoorkeuren.ce.vue'
  ),
  'communicatievoorkeuren-afmelden': import(
    './components/Communicatievoorkeuren/Afmelden/CommunicatievoorkeurenAfmelden.ce.vue'
  ),
  'cookie-toestemming': import('./components/CookieToestemming/CookieToestemming.ce.vue'),
  'detailgegevens-titel': import('./components/DetailgegevensTitel/DetailgegevensTitel.ce.vue'),
  'digitaal-post': import('./components/DigitaalPost/DigitaalPost.ce.vue'),
  'digitaal-post-popup': import('./components/DigitaalPostPopup/DigitaalPostPopup.ce.vue'),
  'documenten-betalingsverleden': import(
    './components/DocumentenBetalingsverleden/DocumentenBetalingsverleden.ce.vue'
  ),
  'documenten-digitale-polis': import(
    './components/DocumentenDigitalePolis/DocumentenDigitalePolis.ce.vue'
  ),
  emailadres: import('./components/Emailadres/Emailadres.ce.vue'),
  'emailadres-bevestigen': import('./components/EmailadresBevestigen/EmailadresBevestigen.ce.vue'),
  'emailadres-opgeven': import('./components/EmailadresOpgeven/EmailadresOpgeven.ce.vue'),
  'financiele-gegevens': import('./components/FinancieleGegevens/FinancieleGegevens.ce.vue'),
  'groene-kaart': import('./components/GroeneKaart/GroeneKaart.ce.vue'),
  'groene-kaart-aanvragen': import('./components/GroeneKaartAanvragen/GroeneKaartAanvragen.ce.vue'),
  'huidige-huisdieren-declaraties': import(
    './components/Verzekeringdetails/HuidigeHuisdierenDeclaraties/HuidigeHuisdierenDeclaraties.ce.vue'
  ),
  'huisdieren-declaratie-details': import(
    './components/HuisdierenDeclaratieDetails/HuisdierenDeclaratieDetails.ce.vue'
  ),
  'huisdieren-declaraties': import(
    './components/HuisdierenDeclaraties/HuisdierenDeclaraties.ce.vue'
  ),
  'idin-banner': import('./components/Banners/IdinBanner.ce.vue'),
  'idin-gegevens': import('./components/IdinGegevens/IdinGegevens.ce.vue'),
  inloggen: import('./components/Inloggen/Inloggen.ce.vue'),
  kilometerbundels: import('./components/Kilometerbundels/Kilometerbundels.ce.vue'),
  'korting-aanvragen': import('./components/KortingAanvragen/KortingAanvragen.ce.vue'),
  'link-block': import('./components/LinkBlock/LinkBlock.ce.vue'),
  login: import('./components/Login/login.ce.vue'),
  'login-panel': import('./innerComponents/LoginPanel/LoginPanel.ce.vue'),
  'mfa-gegevens': import('./components/MfaGegevens/MfaGegevens.ce.vue'),
  'mfa-aanvragen': import('./components/MfaAanvragen/MfaAanvragen.ce.vue'),
  'mfa-gelukt': import('./components/MfaAanvragen/MfaGelukt.ce.vue'),
  'mobiel-telefoonnummer': import('./components/MobielTelefoonnummer/MobielTelefoonnummer.ce.vue'),
  modal: import('./innerComponents/Modal/Modal.ce.vue'),
  'naam-titel': import('./components/NaamTitel/NaamTitel.ce.vue'),
  paginamenu: import('./components/Paginamenu/Paginamenu.ce.vue'),
  'personalisatie-banner': import('./components/Banners/PersonalisatieBanner.ce.vue'),
  'persoons-gegevens': import('./components/Persoonsgegevens/Persoonsgegevens.ce.vue'),
  'recht-op-inzage': import('./components/RechtOpInzage/RechtOpInzage.ce.vue'),
  'schadeformulier-aanvragen': import(
    './components/SchadeformulierAanvragen/SchadeformulierAanvragen.ce.vue'
  ),
  'vast-telefoonnummer': import('./components/VastTelefoonnummer/VastTelefoonnummer.ce.vue'),
  verhuiscase: import('./components/Verhuiscase/Verhuiscase.ce.vue'),
  'verzekering-keuze': import('./components/VerzekeringKeuze/VerzekeringKeuze.ce.vue'),
  verzekeringdetails: import('./components/Verzekeringdetails/Verzekeringdetails.ce.vue'),
  verzekeringsoverzicht: import('./components/Verzekeringsoverzicht/Verzekeringsoverzicht.ce.vue'),
  'verzekeringsoverzicht-download-pdf': import(
    './components/VerzekeringsoverzichtDownloadPdf/VerzekeringsoverzichtDownloadPdf.ce.vue'
  ),
  'verzekeringsoverzicht-download-xml': import(
    './components/VerzekeringsoverzichtDownloadXml/VerzekeringsoverzichtDownloadXml.ce.vue'
  ),
  wrapper: import('./components/MfaWrapper/MfaWrapper.ce.vue'),
  'wijziging-opgeslagen-melding': import(
    './innerComponents/WijzigingOpgeslagenMelding/WijzigingOpgeslagenMelding.ce.vue'
  ),
  'zorg-banner-eerste': import('./components/Banners/ZorgBannerEerste.ce.vue'),
  'zorg-banner-tweede': import('./components/Banners/ZorgBannerTweede.ce.vue'),
  zorgpas: import('./components/Zorgpas/Zorgpas.ce.vue'),
};

export default webComponents;
