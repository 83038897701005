export const KPCB_API_BASE = `${import.meta.env.VITE_KPCB_API_ENDPOINT}`;

export const DIGITALE_COMMUNICATIE_COOKIE_KEY = 'digitaleCommunicatie';
export const DIGITAAL_POST_POPUP_NIET_GETOOND_VOOR_X_DAGEN = 90; // in dagen

export const IDIN_BANNER_COOKIE_KEY = 'idinProactief';
export const IDIN_BANNER_NIET_GETOOND_VOOR_X_DAGEN = 14;
export const IDIN_BANNER_MAX_AANTAL_KEREN_TONEN = 1;
export const PERSONALISATIE_BANNER_COOKIE_KEY = 'commBanner';
export const PERSONALISATIE_BANNER_NIET_GETOOND_VOOR_X_DAGEN = 1;
export const PERSONALISATIE_BANNER_MAX_AANTAL_KEREN_TONEN = 5;

export const PERSONALISATIE_BANNER_UTM_SOURCE_CROSSSELL = 'mijn_ohra';
export const PERSONALISATIE_BANNER_UTM_MEDIUM_CROSSSELL = 'internal';
export const PERSONALISATIE_BANNER_UTM_CAMPAIGN_CROSSSELL = 'cross_sell';
export const PERSONALISATIE_BANNER_UTM_CONTENT_CROSSSELL = 'banner';

export const OPSTAL_OPZEGGEN_DIALOOG_URL = '/mijnohraopzeggenopstal';
export const OPSTAL_OPZEGGEN_DIALOOG_URL_APP = '/appopzeggenopstal';
export const OPSTAL_AANVRAGEN_DIALOOG_URL = '/opstalberekenenverhuiscase';
export const INBOEDEL_DIALOOG_URL = '/mijnohrawijzigeninboedel?scenario=verhuizen';
export const INBOEDEL_DIALOOG_URL_APP = '/appwijzigeninboedel?scenario=verhuizen';

export const DATAPORTABILITEIT_URL = '/kpcb/api/dataportabiliteit';
