import webComponentsConstants from './WebComponentsConstants';
import { defineCustomElement, onMounted } from 'vue';
import 'construct-style-sheets-polyfill';

const ohgsStyleSheet = new CSSStyleSheet();

function getOHGSStyleSheet() {
  if (ohgsStyleSheet.cssRules.length === 0) {
    try {
      const ohgsStyles: StyleSheet | undefined = Array.from(document.styleSheets).find(styleSheet =>
        styleSheet.href?.includes('ohra-global.min.css')
      );
      const cssRules: CSSStyleRule[] = [...((ohgsStyles as any)?.cssRules ?? [])];
      cssRules.reverse();
      cssRules.forEach(({ cssText }) => ohgsStyleSheet.insertRule(cssText));
    } catch (error) {
      //
    }
  }
}

export function registerWebComponents() {
  getOHGSStyleSheet();
  Object.entries(webComponentsConstants).forEach(([tagName, component]) => {
    const webComponentName = `kpcf-${tagName}`;

    const isElementInDom = document.body.getElementsByTagName(webComponentName).length > 0;

    const isWebComponentAlreadyDefined = customElements.get(webComponentName);
    if (!isWebComponentAlreadyDefined && isElementInDom) {
      component.then(module => {
        const isWebComponentAlreadyDefined = customElements.get(webComponentName);

        if (!isWebComponentAlreadyDefined) {
          const { styles, ...vueComponent } = module.default;

          if (typeof vueComponent.props === 'undefined') {
            vueComponent.props = {};
          }

          // Extend the vue component with the custom element class to support adoptedStyleSheets
          class WebComponent extends defineCustomElement(vueComponent) {
            connectedCallback() {
              super.connectedCallback();

              if (this.shadowRoot) {
                const styleSheet = new CSSStyleSheet();
                styleSheet.replaceSync(styles?.at(0));

                this.shadowRoot.adoptedStyleSheets = [ohgsStyleSheet, styleSheet];
              }
            }
          }

          customElements.define(webComponentName, WebComponent);
        }
      });
    }
  });
}

export function useWebComponents() {
  onMounted(registerWebComponents);
}
