import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import { KPCB_API_BASE } from '../Constants/Constants';
import { Api, type ApiConfig } from '../__generated__/Api';

const axiosConfig: AxiosRequestConfig = {
  baseURL: KPCB_API_BASE ?? '/',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

class ApiExtended extends Api<any> {
  constructor({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    securityWorker,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    secure,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    format,
    ...axiosConfig
  }: ApiConfig<any> = {}) {
    super(axiosConfig);
  }

  // Leave this here when a new endpoint must be implemented but is not available in OpenAPI yet
  // casesResourceTemp = {
  //   afwijzen: (uuid: string, params: RequestParams = {}) =>
  //     api.request<void, any>({
  //       path: `/cases/afwijzen/${uuid}`,
  //       method: 'POST',
  //       format: 'json',
  //       ...params,
  //     }),
  // };
}

// Normally do not use the ApiExtended but Api
const api = new ApiExtended(axiosConfig);
// const api = new Api(axiosConfig);

api.instance.interceptors.response.use(
  response => response,
  (error: any): Promise<CustomAxiosError> => {
    return Promise.reject({ ...error.response, message: error.message });
  }
);

export interface CustomAxiosError extends AxiosResponse {
  message: string;
}

export { api, axiosConfig };
